







































import { Component, Vue, Watch } from "vue-property-decorator";

import { TemplateModel } from "@/templates/models/TemplateModel";
import { AppSection, MULTI_APP } from "@/shared/models";

@Component
export default class TemplatesView extends Vue {
  isDialogVisible = false;
  deletedTemplate: TemplateModel | null = null;

  get deletedTemplateName(): string {
    return this.deletedTemplate?.name ?? "";
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isTemplateDeleting(): boolean {
    return this.$store.state.templateStore.isTemplateDeleting;
  }

  @Watch("$route", { immediate: true })
  watchRoute() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang(`template.title.${this.$route.name?.toLowerCase()}`)
    );
  }

  @Watch("applicationId", { immediate: true })
  @Watch("$route.name")
  fetchTemplates() {
    const actionName: Record<string, string> = {
      [AppSection.REPORT_TEMPLATES]: "fetchReportTemplates",
      [AppSection.FUNNEL_TEMPLATES]: "fetchFunnelTemplates",
    };

    this.$store.dispatch(actionName[this.$route.name as AppSection], {
      applicationId:
        this.applicationId === MULTI_APP ? null : this.applicationId,
    });
  }

  showDeleteDialog(item: TemplateModel) {
    this.deletedTemplate = item;
    this.isDialogVisible = true;
  }

  hideDeleteDialog() {
    this.isDialogVisible = false;
  }

  deleteTemplate() {
    this.$store.dispatch("deleteTemplate", this.deletedTemplate).then(() => {
      this.hideDeleteDialog();
      this.fetchTemplates();
    });
  }
}
